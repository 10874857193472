import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default function useTrabajoList() {
  const toast = useToast()

  const refTrabajoListTable = ref(null)

  const tableColumns = [
    { label: "Acciones", key: "actions", sortable: false, thStyle: { minWidth: "200px" } },
    { label: "Nº Parte", key: "codigo", sortable: false },
    { label: "Estado", key: "estado", sortable: false },
    { label: "Fecha Asignación", key: "fecha_del_parte" },
    { label: "Fecha inicio", key: "fecha_de_inicio", sortable: false },
    { label: "Fecha fin", key: "fecha_de_fin" },
    { label: "Zona", key: "zona.nombre" },
    { label: "Jefe de equipo", key: "jefe_equipo" },
    { label: "Encomienda", key: "encomienda" },
  ]

  const perPage = ref(50)
  const totalTrabajo = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const filterFechaInicio = ref(null)
  const filterFechaFin = ref(null)
  const isFiltering = ref(false)
  const filterZona = ref(null)
  const filterEstado = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTrabajoListTable.value ? refTrabajoListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTrabajo.value,
    }
  })

  const refetchData = () => {
    refTrabajoListTable.value.refresh()
  }
  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const getTrabajoStatistics = async () => {
    return store.dispatch('app-trabajo/getTrabajoStatistics')
  }

  const getEncomiendas = async () => {
    return store.dispatch('app-trabajo/getEncomiendas')
  }

  const filterData = () => {
    isFiltering.value = true

    if (refTrabajoListTable.value) refetchData()
  }

  const clearFilter = () => {
    isFiltering.value = false
    filterFechaInicio.value = null
    filterFechaFin.value = null
    filterEstado.value = null
    filterZona.value = null
    searchQuery.value = ''

    refetchData()
  }

  const fetchTrabajo = (ctx, callback) => {
    const body = isFiltering.value ? {
      search: searchQuery.value,
      paginate: perPage.value,
      fecha_inicio: filterFechaInicio.value,
      fecha_fin: filterFechaFin.value,
      estado: filterEstado.value,
      zona: filterZona.value,
      user_role: store.state.auth.user.fk_role,
    } : {
      search: searchQuery.value,
      paginate: perPage.value,
      user_role: store.state.auth.user.fk_role,
    }

    store
      .dispatch('app-trabajo/fetchTrabajo', {
        body,
        headers: {
          'X-Sort': `partes_de_trabajo.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalTrabajo.value = total

        let data = []
        data = res.data
        if (callback)
          callback(data)
        else return data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Trabajo list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  return {
    fetchTrabajo,
    tableColumns,
    perPage,
    currentPage,
    totalTrabajo,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTrabajoListTable,
    getTrabajoStatistics,
    getEncomiendas,

    refetchData,
    filterFechaFin,
    filterFechaInicio,
    isFiltering,
    clearFilter,
    filterData,
    filterEstado,
    filterZona,
    parseDate,
  }
}