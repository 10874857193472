import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import router from '@/router'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    module: 'trabajo',
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async getEncomiendas({ state, commit }) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.getEncomiendas()
        return res
      } catch (e) {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getTrabajoStatistics({ state, commit }) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.getTrabajoStatistics()
        return res
      } catch (e) {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async updateEstado({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.updateEstado(payload)
        return res
      } catch (e) {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchTrabajo({ state, commit }, payload) {
      const { user } = store.state.auth
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        const trabajos = await controller.getAllTrabajos(payload)
        if (user.fk_role !== 2) return trabajos
        const trabajosFiltrados = trabajos.data.filter(trabajo => trabajo.jefe_equipo?.id === user.id)
        return { ...trabajos, data: trabajosFiltrados }
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async fetchTrabajoById({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        return await controller.getById(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addTrabajo({ commit, state }, trabajo) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        const res = await controller.createTrabajo(trabajo)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return res.data
      } catch (e) {
        store.commit('app/SET_ALERT', e)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async uploadTrabajoFiles({ commit, state }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.uploadFiles(payload)
        // store.commit("app/SET_ALERT", messageAlerts.a200Alert);
        return null
      } catch (e) {
        store.commit('app/SET_ALERT', e)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deleteTrabajo({ commit, state }, trabajo) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.deleteById(trabajo)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
