<template>
  <b-card>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Editar"
        active
      >
        <div v-if="trabajoData">
          <!-- Codigo -->
          <b-row align-h="between">
            <b-col>
              <h3
                v-if="trabajoData.codigo"
                class="text-primary"
              >
                Codigo #{{ trabajoData.codigo }}
              </h3>
              <h3
                v-else
                class="text-primary"
              >
                Nueva parte de trabajo
              </h3>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              md="4"
              sm="6"
            >
              <b-form-group
                label="Estado*"
                label-for="estadp"
              >
                <v-select
                  v-model="trabajoData.estado"
                  class="vs--primary"
                  :clearable="false"
                  placeholder="Estado"
                  label="label"
                  :options="filteredEstados"
                  varia
                />
              </b-form-group>
              <b-row
                align-h="end"
                class="mx-0"
              >
                <b-form-checkbox
                  v-model="trabajoData.is_urgent"
                  :disabled="isOperario ? true : false"
                >Parte urgente</b-form-checkbox>
              </b-row>
            </b-col>
          </b-row>

          <validation-observer
            ref="refFormObserver"
            #default="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(saveTrabajo)">
              <b-row class="mb-1">
                <!-- Peticionario -->
                <b-col
                  cols="12"
                  lg="6"
                  md="4"
                  sm="6"
                  xs="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Peticionario"
                    rules="required"
                  >
                    <b-form-group
                      label="Peticionario*"
                      label-for="fh-peticionario"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="trabajoData.peticionario"
                        :options="peticionarioOptions"
                        :reduce="(val) => val.value"
                        label="label"
                        placeholder="Seleccione"
                        :clearable="false"
                        :disabled="isOperario ? true : false"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <!-- Otros -->
                <b-col
                  v-if="trabajoData.peticionario == 'otros'"
                  cols="12"
                  lg="6"
                  md="3"
                  sm="6"
                  xs="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Otro"
                    rules="required"
                  >
                    <b-form-group
                      label="Otro*"
                      label-for="fh-otros"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-input
                        id="fh-otros"
                        v-model="trabajoData.otros"
                        placeholder="Otros"
                        :disabled="isOperario ? true : false"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <!-- Encomienda -->
                <b-col
                  cols="12"
                  lg="4"
                  md="6"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Encomienda*"
                    rules="required"
                  >
                    <b-form-group
                      label="Encomienda*"
                      label-for="fh-encomienda"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="fh-encomienda"
                        v-model="trabajoData.encomienda"
                        :options="encomiendas"
                        :reduce="(val) => val.id"
                        label="nombre"
                        placeholder="Seleccione"
                        :clearable="true"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="trabajoData.encomienda"
                  cols="12"
                  lg="4"
                  md="6"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Tareas"
                  >
                    <b-form-group
                      label="Tarea"
                      label-for="fh-task"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="fh-task"
                        v-model="trabajoData.task"
                        :options="getTasks()"
                        :reduce="(val) => val.id"
                        label="nombre"
                        placeholder="Seleccione"
                        :clearable="true"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Realizado por -->
                <b-col
                  cols="12"
                  lg="4"
                  md="6"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Asignado a"
                  >
                    <b-form-group
                      label="Asignado a"
                      label-for="Asignado a"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="Asignado a"
                        v-model="trabajoData.asignado_a"
                        :options="usersList"
                        label="name"
                        autofocus
                        placeholder="Seleccione"
                        :clearable="true"
                        :reduce="(val) => val.id"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <!-- Parte date -->
                <b-col
                  cols="12"
                  lg="4"
                  md="4"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Fecha del parte"
                    rules="required"
                  >
                    <b-form-group
                      label="Fecha del parte*"
                      label-for="fh-fecha_del_parte"
                      placeholder="Selecciona una fecha"
                      local="es"
                      start-weekday="1"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-datepicker
                        v-model="trabajoData.fecha_del_parte"
                        class="mb-1"
                        selected-variant="primary"
                        placeholder="Selecciona una fecha"
                        local="es"
                        start-weekday="1"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Zona -->
                <b-col
                  cols="12"
                  lg="4"
                  md="4"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Zona"
                    rules="required"
                  >
                    <b-form-group
                      label="Zona*"
                      label-for="fh-zona"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="nombre"
                        v-model="trabajoData.zona_id"
                        :options="zonasList"
                        label="nombre"
                        placeholder="Seleccione"
                        autofocus
                        :clearable="false"
                        :reduce="(val) => val.id"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Lugar de realizacion -->
                <b-col
                  cols="12"
                  lg="4"
                  md="4"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Lugar"
                    rules="required"
                  >
                    <b-form-group
                      label="Lugar de realización*"
                      label-for="fh-lugar"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="fh-lugar"
                        v-model="trabajoData.lugar_de_realizacion"
                        :options="getSubzonas()"
                        label="nombre"
                        placeholder="Seleccione"
                        autofocus
                        :clearable="false"
                        :reduce="(val) => val.id"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Trabajo a realizar -->
              <b-row>
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="Trabajo a realizar"
                    rules="required"
                  >
                    <b-form-group
                      label="Trabajo a realizar*"
                      label-for="textarea-realizar"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-textarea
                        id="textarea-realizar"
                        v-model="trabajoData.trabajo_a_realizar"
                        placeholder="Trabajo a realizar"
                        rows="3"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- Rehacer -->
              <b-row
                v-if="
                  trabajoData.estado === 'rehacer' ||
                    trabajoData.estado.key === 'rehacer'
                "
              >
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="Trabajos a Rehacer"
                    :rules="
                      trabajoData.estado.key === 'rehacer' ? 'required' : ''
                    "
                  >
                    <b-form-group
                      label="Trabajo a rehacer"
                      label-for="rehacer"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-textarea
                        id="rehacer"
                        v-model="trabajoData.rehacer"
                        placeholder="Trabajo a Rehacer"
                        rows="3"
                        :disabled="isOperario ? true : false"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <br>
              <hr style="border-top: 1px solid gainsboro">

              <b-row
                class="mt-1"
                align-v="end"
              >
                <!-- Fecha inicio date -->
                <b-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Fecha inicio trabajo"
                  >
                    <b-form-group
                      label="Fecha inicio trabajo (d-m-Y)"
                      label-for="fecha_de_inicio"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-datepicker
                        id="fecha_de_inicio"
                        v-model="trabajoData.fecha_de_inicio"
                        class="mb-1"
                        selected-variant="primary"
                        placeholder="Selecciona una fecha"
                        local="es"
                        start-weekday="1"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Fecha fin trabajo -->
                <b-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Fecha fin trabajo"
                    :rules="
                      trabajoData.estado.key === 'en_revision' ? 'required' : ''
                    "
                  >
                    <b-form-group
                      label="Fecha fin trabajo (d-m-Y)"
                      label-for="fecha_de_fin"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-datepicker
                        id="fecha_de_fin"
                        v-model="trabajoData.fecha_de_fin"
                        class="mb-1"
                        selected-variant="primary"
                        placeholder="Selecciona una fecha"
                        local="es"
                        start-weekday="1"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- N° empleados -->
                <b-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="N° de empleados"
                    :rules="
                      trabajoData.estado.key === 'terminado' ? 'required' : ''
                    "
                  >
                    <b-form-group
                      label="N° de empleados"
                      label-for="fh-empleados"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-input
                        id="fh-empleados"
                        v-model="trabajoData.numero_empleados"
                        placeholder="N° de empleados"
                        type="number"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Realizado por -->
                <b-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Realizado por"
                  >
                    <b-form-group
                      label="Realizado por"
                      label-for="fh-user"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="nombre"
                        v-model="trabajoData.user_id"
                        :options="usersList"
                        label="name"
                        autofocus
                        placeholder="Seleccione jefe de grupo"
                        :clearable="true"
                        :reduce="(val) => val.id"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Trabajos realizados -->
              <b-row>
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="Trabajos realizados"
                  >
                    <b-form-group
                      label="Trabajos realizados"
                      label-for="textarea-realizado"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-textarea
                        id="textarea-realizado"
                        v-model="trabajoData.trabajos_realizados"
                        placeholder="Trabajos realizados"
                        rows="3"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Incidencias surgidas -->
              <b-row>
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="Incidencias"
                    :rules="
                      trabajoData.estado.key === 'incidencia' ? 'required' : ''
                    "
                  >
                    <b-form-group
                      label="Incidencias surgidas"
                      label-for="extarea-incidencias"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-textarea
                        id="textarea-incidencias"
                        v-model="trabajoData.incidencias"
                        placeholder="Incidencias surgidas"
                        rows="3"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row
                align-h="between"
                class="mx-0"
              >
                <b-col class="pl-0">
                  <small
                    v-if="isUserAdmin"
                    style="color: red"
                  >Coste total materiales: <b>{{ formatCurrency(totalCoste) }}</b></small><br>
                  <b-button
                    :variant="`outline-${
                      materialesRequiredValidation ? 'danger' : 'primary'
                    }`"
                    class="mt-2"
                    @click="materialDialog = true"
                  >
                    <b-badge
                      class="mr-1"
                      variant="primary"
                    >
                      {{ trabajoData.materiales.length || 0 }}
                    </b-badge>
                    MATERIAL UTILIZADO
                  </b-button>

                  <div>
                    <small
                      v-if="materialesRequiredValidation"
                      style="color: red"
                    >El campo de materiales es obligatorio</small>
                  </div>
                </b-col>

                <b-button
                  variant="primary"
                  class="mt-2"
                  type="submit"
                >GUARDAR</b-button>
              </b-row>
            </b-form>
          </validation-observer>

          <!-- add material modal -->
          <b-modal
            id="modal-center"
            v-model="materialDialog"
            hide-footer
            hide-header
            centered
            no-close-on-backdrop
            size="lg"
          >
            <b-card class="ma-0 pa-0 shadow-none">
              <b-row class="mb-2">
                <!-- material col -->
                <b-col cols="9">
                  <b-form-group label="Añade el material usado">
                    <v-select
                      id="nombre"
                      v-model="material"
                      :options="filteredMateriales"
                      label="descripcion"
                      autofocus
                      placeholder="Seleccione"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <!-- unidades col -->
                <b-col
                  cols="12"
                  lg="2"
                  md="2"
                  sm="2"
                >
                  <b-form-group label="Cantidad">
                    <b-form-input
                      v-model="unidades"
                      placeholder="Cantidad"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <!-- button col -->
                <b-col cols="1">
                  <b-button
                    class="btn-icon rounded-circle mt-2"
                    @click="addMaterialToList"
                  >
                    <feather-icon
                      size="20"
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                :items="trabajoData.materiales"
                :fields="isUserAdmin ? materialesTableHeaders : materialesTableHeaders.filter(x => x.key !== 'coste')"
                empty-text="No materiales"
                show-empty
              >
                <template #cell(descripcion)="data">
                  {{ data.item.descripcion_custom ? data.item.descripcion_custom : data.item.descripcion }}
                </template>
                <template #cell(actions)="data">
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="deleteMaterial(data.index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
              <b-row
                class="ma-2 mt-4"
                align-h="between"
              >
                <b-col cols="6">
                  <div>
                    <p
                      v-if="isUserAdmin"
                      style="color: red"
                    >Coste total materiales:<br><span style="font-size: 16px;"><b>{{ formatCurrency(totalCoste) }}</b></span></p>
                  </div>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b-button
                    class="mr-2"
                    variant="danger"
                    @click="materialDialog = false"
                  >Cancelar</b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="success"
                    class="mr-1"
                    @click="saveDialog"
                  >
                    GUARDAR
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-modal>
        </div>
      </b-tab>
      <b-tab title="Documentos">
        <div v-if="trabajoData">
          <b-row>
            <b-col
              cols="12"
              lg="10"
              md="10"
              sm="10"
            >
              <b-row align-h="end">
                <b-col>
                  <b-form-file
                    v-model="file"
                    placeholder="Elija un archivo o suéltelo aquí..."
                    drop-placeholder="Suelta el archivo aquí..."
                    browse-text="Navegar"
                  />
                </b-col>
                <b-col>
                  <b-button
                    variant="outline-primary"
                    :disabled="!file"
                    @click="addFileToArray()"
                  >Subir archivo <feather-icon
                    class="ml-1"
                    icon="PlusIcon"
                  /></b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
            >
              <b-button
                variant="primary"
                block
                @click="saveTrabajo()"
              >GUARDAR</b-button>
            </b-col>
          </b-row>
          <b-table
            :items="trabajoData.files"
            :fields="filesTableFields"
            responsive
            class="mt-2"
          >
            <!-- File -->
            <template #cell(file)="data">
              <span>{{
                data.item.file ? data.item.file.name : data.item.filepath
              }}</span>
            </template>

            <template #cell(description)="data">
              <b-link
                v-if="data.item.description"
                @click="
                  openDescModal = true;
                  fileDescription = data.item.description;
                  selectedItem = data.item;
                "
              >
                <span style="font-style: italic">
                  {{ data.item.description }}
                  <feather-icon icon="EditIcon" />
                </span>
              </b-link>
              <b-link
                v-else
                @click="
                  openDescModal = true;
                  selectedItem = data.item;
                "
              >
                <span style="font-style: italic">
                  Añadir descripción
                  <feather-icon icon="EditIcon" />
                </span>
              </b-link>

              <b-modal
                v-model="openDescModal"
                centered
                title="Descripcion"
                @ok="
                  selectedItem.description = fileDescription;
                  fileDescription = null;
                  selectedItem = null;
                "
                @hide="
                  fileDescription = null;
                  selectedItem = null;
                "
                @hidden="
                  fileDescription = null;
                  selectedItem = null;
                "
              >
                <b-form-input v-model="fileDescription" />
              </b-modal>
            </template>

            <!-- Uploaded by -->
            <template #cell(uploaded_by)="data">
              <span>{{ data.item.uploaded_by.name }}</span>
            </template>

            <!-- Column: Created at -->
            <template #cell(created_at)="data">
              <span>
                {{
                  data.item.created_at ? parseDate(data.item.created_at) : "-"
                }}
              </span>
            </template>

            <!-- Column: Update at -->
            <template #cell(updated_at)="data">
              <span>
                {{
                  data.item.updated_at ? parseDate(data.item.updated_at) : "-"
                }}
              </span>
            </template>

            <!-- Actions -->
            <template #cell(actions)="data">
              <b-row align-h="center">
                <b-button
                  v-if="data.item.filepath"
                  variant="success"
                  size="sm"
                  @click="viewFile(data.item)"
                ><feather-icon icon="EyeIcon" /></b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="deleteFile(data.item, data.index)"
                ><feather-icon icon="Trash2Icon" /></b-button>
              </b-row>
            </template>
          </b-table>
        </div>
      </b-tab>
      <b-tab title="Mapa">
        <b-row
          align-h="between"
          class="mx-0 mb-1"
        >
          <b-col>
            <p>
              Haga clic en el mapa para colocar un marcador. Para mover un
              marcador existente, haz clic en el marcador y cuando el cursor se
              mueva, comienza a arrastrarlo al nuevo lugar.
            </p>
          </b-col>
          <b-col
            cols="12"
            lg="5"
            md="5"
            sm="7"
          >
            <b-row align-h="end">
              <b-button
                :variant="isDeleting ? 'outline-primary' : 'primary'"
                class="mr-1"
                @click="isDeleting = false"
              >
                <feather-icon icon="MapPinIcon" />
              </b-button>
              <b-button
                :variant="isDeleting ? 'danger' : 'outline-danger'"
                class="mr-2"
                @click="isDeleting = !isDeleting"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="primary"
                @click="saveTrabajo()"
              >GUARDAR</b-button>
            </b-row>
          </b-col>
        </b-row>
        <div
          id="map"
          style="height: 600px"
        />
      </b-tab>
      <!-- <b-tab title="Canvas" @click="loadImageOnCanvas()">
        <div v-if="trabajoData">
          <div class="mb-2">
            <canvas-drawer-vue />
          </div>
          <b-row align-h="between">
            <b-col cols="12" lg="8" md="8" sm="6">
              <b-form-file
                v-model="image"
                placeholder="Elija un archivo o suéltelo aquí..."
                drop-placeholder="Suelta el archivo aquí..."
                browse-text="Navegar"
                @change="addImageToCanvas"
              ></b-form-file>
            </b-col>
            <b-col cols="12" lg="4" md="4" sm="6">
              <b-button variant="primary" @click="saveTrabajo()" block
                >GUARDAR</b-button
              >
            </b-col>
          </b-row>
        </div>
      </b-tab> -->
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormSelect,
  BFormDatepicker,
  BFormTextarea,
  BModal,
  BCardTitle,
  BTable,
  BBadge,
  BFormCheckbox,
  BTabs,
  BTab,
  BFormFile,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import { Loader } from '@googlemaps/js-api-loader'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import Trabajo, { FileModel } from '@/models/Trabajo.js'
import store from '@/store'
import router from '@/router/index.js'
import trabajoStoreModule from '@/views/work/trabajo/trabajoStoreModule'
import { ref, onUnmounted } from '@vue/composition-api'
import useZonasList from '@/views/work/zonas/useZonasList'
import zonaStoreModule from '@/views/work/zonas/zonaStoreModule'
import useUsersList from '@/views/system/user/useUsersList'
import userStoreModule from '@/views/system/user/userStoreModule'
import useMaterialesList from '@/views/almacen/materiales/useMaterialesList'
import materialesStoreModule from '@/views/almacen/materiales/materialesStoreModule'
import useTrabajoList from '@/views/work/trabajo/useTrabajoList'
import useTareasList from '@/views/work/tareas/useTareasList'
import tareaStoreModule from '@/views/work/tareas/tareaStoreModule'
import { FILES_PUBLIC_PATH } from '@/api/v1'
// import CanvasDrawerVue from "./CanvasDrawer.vue";

export default {
  name: 'TrabajoEdit',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormDatepicker,
    BFormTextarea,
    BModal,
    BCardTitle,
    vSelect,
    BTable,
    BBadge,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BAvatar,
    BLink,
    // CanvasDrawerVue,
  },
  data() {
    return {
      map: null,
      required,
      peticionarioOptions: [
        { label: 'Alcalde / Alcaldesa', value: 'alcalde' },
        { label: 'Consejeros Delegados', value: 'consejeros_delegados' },
        { label: 'Gerente', value: 'gerente' },
        { label: 'GECOR', value: 'gecor' },
        { label: 'Otros', value: 'otros' },
      ],
      materialesTableHeaders: [
        { label: 'Material', key: 'descripcion', thStyle: { minWidth: '400px' } },
        { label: 'Qtd.', key: 'unidades' },
        { label: 'Coste', key: 'coste' },
        { label: '', key: 'actions' },
      ],
      filesTableFields: [
        { key: 'file', label: 'Archivo' },
        { key: 'description', label: 'Description' },
        { key: 'uploaded_by', label: 'Subido por' },
        { key: 'created_at', label: 'Creado en' },
        { key: 'updated_at', label: 'Actualizado en' },
        { key: 'actions', label: 'Acciones', thStyle: { minWidth: '120px' } },
      ],
      imagesTableFields: [
        { key: 'file', label: 'Imagen' },
        { key: 'uploaded_by', label: 'Subido por' },
        { key: 'created_at', label: 'Creado en' },
        { key: 'updated_at', label: 'Actualizado en' },
        { key: 'actions', label: 'Acciones', thStyle: { minWidth: '120px' } },
      ],
      materialDialog: false,
      material: null,
      unidades: 1,
      file: null,
      image: null,
      openDescModal: false,
      fileDescription: null,
      selectedItem: null,
      canvas: null,
      canvasContext: null,
      mapLabels: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      totalCoste: 0,
      mapLabelIndex: 0,
      selectedMarker: null,
      materialesRequiredValidation: false,
      isDeleting: false,
      gMapMarkers: [],
    }
  },
  computed: {
    filteredEstados() {
      const allowedEstadosForOperario = [
        'incidencia',
        'en_revision',
        'iniciado',
        'cancelado',
        'asignado',
        'rehacer',
      ]
      let estados = []
      if (this.user.fk_role === 2) {
        estados = this.estados?.filter(item => allowedEstadosForOperario.includes(item.key))
      } else {
        estados = this.estados
      }
      return estados
    },
    filteredMateriales() {
      const listMateriales = this.materialesList.filter(x => x.tipo !== 'uniforme')

      this.calcCostMateriales()

      return listMateriales.map(material => ({
        id: material.id, // Ajusta según la estructura de tu objeto material
        descripcion: material.descripcion_custom ? material.descripcion_custom : material.descripcion,
        coste: material.coste,
      })).filter(material => !this.trabajoData.materiales.some(y => y.fk_material === material.id))
    },
    isUserAdmin() {
      return store.state.auth.user.fk_role === 1
    },
    user() {
      return this.$store.state.auth.user
    },
    isOperario() {
      return this.user.fk_role === 2
    },
  },
  watch: {
    trabajoData: {
      handler(after, before) {
        if ((before == null) & (after != null)) {
          this.loadMarkers()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadMap()
  },
  methods: {
    getMousePosition(e) {
      const rect = this.canvas.getBoundingClientRect()
      const scaleX = this.canvas.width / rect.width
      const scaleY = this.canvas.height / rect.height
      const offsetX = rect.left
      const offsetY = rect.top
      const x = (e.clientX - offsetX) * scaleX
      const y = (e.clientY - offsetY) * scaleY
      return { x, y }
    },
    deleteFile(item, index) {
      if (!this.trabajoData.files_to_delete) this.trabajoData.files_to_delete = []
      this.trabajoData.files_to_delete.push(item)
      this.trabajoData.files.splice(index, 1)
    },
    viewFile(item) {
      const url = `${FILES_PUBLIC_PATH}/${item.filepath}`
      window.open(url)
    },
    addFileToArray() {
      if (!this.trabajoData.files) this.trabajoData.files = []
      this.trabajoData.files.unshift(
        new FileModel({
          file: this.file,
          filepath: null,
          uploaded_by: this.user,
          description: this.fileDescription,
        }),
      )
      this.file = null
      this.fileDescription = null
    },
    async addImageToArray() {
      const url = await this.canvas.toDataURL('image/webp')

      const res = await fetch(url)
      const blob = await res.blob()
      const imageFile = new File([blob], `${Date.now()}.webp`, {
        type: 'image/webp',
      })

      this.trabajoData.images = {
        file: imageFile,
        filepath: null,
        uploaded_by: this.user,
      }

      this.image = null
    },
    // addImageToCanvas(event) {
    //   this.canvas = document.getElementById("drawing-board");
    //   this.canvasContext = this.canvas.getContext("2d");
    //   const file = event.target.files[0];
    //   const fileReader = new FileReader();
    //   const th = this;

    //   fileReader.onload = function (event) {
    //     const backgroundImage = new Image();
    //     backgroundImage.onload = function () {
    //       th.canvasContext.drawImage(
    //         backgroundImage,
    //         0,
    //         0,
    //         th.canvas.width,
    //         th.canvas.height
    //       );
    //     };

    //     backgroundImage.src = event.target.result;
    //   };

    //   fileReader.readAsDataURL(file);
    // },
    // async loadImageOnCanvas() {
    //   if (
    //     this.trabajoData &&
    //     this.trabajoData.images &&
    //     this.trabajoData.images.length > 0
    //   ) {
    //     this.canvas = document.getElementById("drawing-board");
    //     this.canvasContext = this.canvas.getContext("2d");

    //     const url = `${FILES_PUBLIC_PATH}/${this.trabajoData.images[0].filepath}`;
    //     const res = await fetch(url);
    //     const blob = await res.blob();
    //     const imageUrl = URL.createObjectURL(blob);

    //     const backgroundImage = new Image();
    //     const th = this;
    //     backgroundImage.onload = function () {
    //       th.canvasContext.drawImage(
    //         backgroundImage,
    //         0,
    //         0,
    //         th.canvas.width,
    //         th.canvas.height
    //       );
    //     };

    //     backgroundImage.src = imageUrl;
    //   }
    // },
    getSubzonas() {
      const parentZona = this.trabajoData.zona_id
      return this.subZonas.filter(s => s.parent.id === parentZona)
    },
    getTasks() {
      const parentTask = this.trabajoData.encomienda
      return this.tasks.filter(e => e.parent && e.parent.id === parentTask)
    },
    saveDialog() {
      this.material = null
      this.unidades = 1
      this.materialDialog = false
    },
    async saveTrabajo() {
      if (
        this.trabajoData.materiales.length == 0
        // this.trabajoData.estado.key === "iniciado" ||
        && (this.trabajoData.estado.key === 'rehacer'
          || this.trabajoData.estado.key === 'terminado'
          || this.trabajoData.estado.key === 'en_revision')
      ) {
        this.materialesRequiredValidation = true
      } else {
        this.trabajoData.lugar_de_realizacion = this.trabajoData
          .lugar_de_realizacion.id
          ? this.trabajoData.lugar_de_realizacion.id
          : this.trabajoData.lugar_de_realizacion
        this.trabajoData.encomienda = this.trabajoData.encomienda.id
          ? this.trabajoData.encomienda.id
          : this.trabajoData.encomienda
        if (this.trabajoData.task) {
          this.trabajoData.task = this.trabajoData.task.id
            ? this.trabajoData.task.id
            : this.trabajoData.task
        }

        this.trabajoData.estado = this.trabajoData.estado.key

        // Manage files
        this.trabajoData.raw_files = []
        this.trabajoData.raw_image = null

        // if (this.canvas) {
        //   await this.addImageToArray();
        // }

        this.trabajoData.files.forEach((element, index) => {
          if (element.file instanceof File) {
            this.trabajoData.raw_files.push(element.file)
            delete this.trabajoData.files[index].file
          }
        })

        if (
          this.trabajoData.images
          && this.trabajoData.images.file instanceof File
        ) {
          this.trabajoData.raw_image = this.trabajoData.images.file
          delete this.trabajoData.images.file
        }

        // Update to asignado in case estado was no_asignado but asignado_a was defined
        if (
          this.trabajoData.estado === 'no_asignado'
          && this.trabajoData.asignado_a
        ) {
          this.trabajoData.estado = 'asignado'
        } else if (
          this.trabajoData.estado === 'no_asignado'
          && !this.trabajoData.asignado_a
        ) {
          // In case status changes no_asignado, remove the asignado_a
          this.trabajoData.asignado_a = null
          this.trabajoData.estado = 'no_asignado'
        }
        this.trabajoData.user_id = this.trabajoData.asignado_a
        if (this.trabajoData.estado === 'rehacer') {
          this.trabajoData.fecha_de_fin = null
        }

        // Update fecha_de_inicio in case estado changes to iniciado
        if (
          this.trabajoData.estado == 'iniciado'
          && !this.trabajoData.fecha_de_inicio
        ) {
          const temp_data = new Date(Date.now())
          const año = temp_data.getFullYear()
          const mes = String(temp_data.getMonth() + 1).padStart(2, '0')
          const dia = String(temp_data.getDate()).padStart(2, '0')
          const fechaFormateada = `${año}-${mes}-${dia}`
          this.trabajoData.fecha_de_inicio = fechaFormateada
        }
        const { id } = await store.dispatch(
          'app-trabajo/addTrabajo',
          this.trabajoData,
        )
        await store.dispatch('app-trabajo/uploadTrabajoFiles', {
          raw_files: this.trabajoData.raw_files ?? [],
          raw_image: this.trabajoData.raw_image ?? null,
          fk_partes_trabajo: id,
          file_mapper: this.trabajoData.files ?? [],
          image_mapper: this.trabajoData.images ?? null,
          files_to_delete: this.trabajoData.files_to_delete ?? [],
        })

        this.$router.push({ name: 'partes-trabajo' })
      }
    },
    formatCurrency(value) {
      if (!value) return '0,00 €'
      const formattedValue = parseFloat(value).toFixed(2).replace('.', ',')
      return `${formattedValue} €`
    },
    addMaterialToList() {
      this.trabajoData.materiales.push({
        fk_material: this.material.id,
        unidades: this.unidades,
        descripcion: this.material.descripcion,
        coste: this.material.coste,
      })

      this.calcCostMateriales()

      this.material = null
      this.unidades = 1

      this.materialesRequiredValidation = false
    },
    calcCostMateriales() {
      this.totalCoste = 0

      this.trabajoData.materiales.forEach(y => {
        let costeLinea = y.coste * y.unidades
        this.totalCoste += costeLinea
      })
    },
    deleteMaterial(index) {
      this.trabajoData.materiales.splice(index, 1)
      this.trabajoData.materiales.forEach(x => {
        this.totalCoste += x.coste
      })
    },
    loadMarkers() {
      this.trabajoData.map_markers.forEach(marker => {
        this.addMarker(marker, false)
      })

      if (
        this.trabajoData.map_markers
        && this.trabajoData.map_markers.length > 0
      ) {
        this.map.setCenter({
          lat: this.trabajoData.map_markers[0].lat,
          lng: this.trabajoData.map_markers[0].lng,
        })
      }
    },
    async loadMap() {
      const loader = new Loader({
        apiKey: 'AIzaSyCYK3Wks1z_x4G813DZwX_foTKm8zagqes',
        version: 'weekly',
      })

      loader.importLibrary('drawing').then(async () => {
        const { Map } = await google.maps.importLibrary('maps')

        this.map = new Map(document.getElementById('map'), {
          center: { lat: 36.596543, lng: -4.639385 },
          zoom: 15,
          mapTypeId: 'hybrid',
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
          ],
        })

        // This event listener calls addMarker() when the map is clicked.
        google.maps.event.addListener(this.map, 'click', event => {
          this.addMarker(
            { lat: event.latLng.lat(), lng: event.latLng.lng() },
            true,
          )
        })
      })
    },
    reloadMapLabels() {
      this.mapLabelIndex = 0
      this.gMapMarkers.forEach(m => {
        m.setMap(null)
      })
      const m = this.trabajoData.map_markers.map(marker => ({ ...marker }))
      this.trabajoData.map_markers = []
      this.gMapMarkers = []
      for (let i = 0; i < m.length; i++) {
        this.addMarker(m[i], true)
      }
    },
    async addMarker(coordinates, isNewMarker) {
      const label = this.mapLabels[this.mapLabelIndex++ % this.mapLabels.length]
      const marker = await new google.maps.Marker({
        position: coordinates,
        label,
        map: this.map,
        draggable: false,
        cursor: 'pointer',
      })

      const th = this
      google.maps.event.addListener(marker, 'click', function () {
        if (th.isDeleting) {
          th.selectedMarker = this
          const index = th.trabajoData.map_markers.findIndex(
            e => e.label == this.label,
          )
          th.trabajoData.map_markers.splice(index, 1)
          this.setMap(null)
          th.reloadMapLabels()
        } else {
          this.setDraggable(true)
          this.setCursor('grab')
          th.selectedMarker = this
        }
      })

      marker.addListener('dragend', value => {
        th.trabajoData.map_markers.map(m => {
          if (m.label === th.selectedMarker.label) {
            m.lat = value.latLng.lat()
            m.lng = value.latLng.lng()
          }
          return m
        })
        th.selectedMarker.setCursor('pointer')
        th.selectedMarker.setDraggable(false)
      })

      if (isNewMarker) {
        this.trabajoData.map_markers.push({
          lat: coordinates.lat,
          lng: coordinates.lng,
          id: coordinates.id ?? null,
          label,
        })
      }

      this.gMapMarkers.push(marker)
    },
  },
  setup() {
    const blankTrabajo = new Trabajo({
      descripcion: null,
      peticionario: '',
      encomienda: null,
      task: null,
      fecha_del_parte: null,
      zona_id: null,
      lugar_de_realizacion: null,
      trabajo_a_realizar: '',
      fecha_de_inicio: null,
      fecha_de_fin: null,
      numero_empleados: null,
      user_id: null,
      asignado_a: null,
      otros: null,
      estado: { key: 'no_asignado', label: 'NO ASIGNADO' },
      materiales: [],
      trabajos_realizados: null,
      incidencias: null,
      id: null,
      is_urgent: false,
      files: [],
      image: null,
      map_markers: [],
    })

    const estados = [
      { key: 'no_asignado', label: 'NO ASIGNADO' },
      { key: 'incidencia', label: 'INCIDENCIA' },
      { key: 'asignado', label: 'ASIGNADO' },
      { key: 'iniciado', label: 'INICIADO' },
      { key: 'rehacer', label: 'REHACER' },
      { key: 'en_revision', label: 'EN REVISION' },
      { key: 'terminado', label: 'TERMINADO' },
      { key: 'cancelado', label: 'CANCELADO' },
    ]

    const trabajoData = ref(null)

    const resetTrabajoData = () => {
      trabajoData.value = JSON.parse(JSON.stringify(blankTrabajo))
    }

    const TRABAJO_STORE_MODULE = 'app-trabajo'
    const ZONAS_APP_STORE_MODULE_NAME = 'app-zona'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const MATERIALES_APP_STORE_MODULE_NAME = 'app-materiales'
    const TASK_APP_STORE_MODULE_NAME = 'app-tarea'

    if (!store.hasModule(TRABAJO_STORE_MODULE)) {
      store.registerModule(TRABAJO_STORE_MODULE, trabajoStoreModule)
    }
    if (!store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONAS_APP_STORE_MODULE_NAME, zonaStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_APP_STORE_MODULE_NAME,
        materialesStoreModule,
      )
    }
    if (!store.hasModule(TASK_APP_STORE_MODULE_NAME)) {
      store.registerModule(TASK_APP_STORE_MODULE_NAME, tareaStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRABAJO_STORE_MODULE)) store.unregisterModule(TRABAJO_STORE_MODULE)
      if (store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) store.unregisterModule(ZONAS_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIALES_APP_STORE_MODULE_NAME)
      if (store.hasModule(TASK_APP_STORE_MODULE_NAME)) store.unregisterModule(TASK_APP_STORE_MODULE_NAME)
    })

    const zonasList = ref([])
    const subZonas = ref([])
    const { listZonas } = useZonasList()
    const { listTareas } = useTareasList()
    const encomiendas = ref([])
    const tasks = ref([])

    listZonas().then(data => {
      zonasList.value = data.filter(zona => !zona.parent)
      subZonas.value = data.filter(zona => zona.parent)
    })

    listTareas().then(data => {
      encomiendas.value = data.filter(task => !task.parent)
      tasks.value = data.filter(task => task.parent)
    })

    const usersList = ref([])
    const { listUsers } = useUsersList()
    listUsers().then(data => {
      usersList.value = data.filter(user => user.fk_role == 2)
    })

    const { parseDate } = useTrabajoList()

    const materialesList = ref([])
    const { listMateriales } = useMaterialesList()
    listMateriales().then(data => {
      materialesList.value = data
    })
    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-trabajo/fetchTrabajoById', router.currentRoute.params.id)
        .then(response => {
          trabajoData.value = response
          trabajoData.value.estado = estados.find(
            e => e.key === trabajoData.value.estado,
          )
        })
        .catch(error => {})
    } else {
      trabajoData.value = blankTrabajo
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetTrabajoData)

    return {
      listZonas,
      zonasList,
      subZonas,
      usersList,
      listUsers,
      materialesList,
      listMateriales,
      trabajoData,

      refFormObserver,
      getValidationState,
      resetForm,
      encomiendas,
      tasks,
      estados,
      parseDate,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

/* Adicione a classe 'vs--primary' para alterar a cor */
.vs--primary .vs__dropdown-toggle {
  border-color: #366301;
  color: #366301;
  background-color: #35630106;
}
</style>
